<template>
    <div>
        <audio-recorder v-if="showRecorder"
        filename="voice-record"
        format="wav"
        :attempts="1"
        :time="time"
        :before-recording="callback"
        :pause-recording="callback"
        :after-recording="callback"
        :select-record="callback"
        :before-upload="callback"
        :successful-upload="callback"
        :failed-upload="callback"
        @export="onExportFile($event)"
        @remove="removeFile"
        :bit-rate="192"/>
    <!-- <audio-player :src="mp3"/> -->
    </div>
</template>

<script>
import AudioRecorder from '@/plugins/audio-recoder/components/recorder.vue'

export default {
    props:{
        time: {
            type: Number,
            default: 30
        }
    },
    components:{
        AudioRecorder
    },
    data(){
        return{
            mp3: '/demo/example.mp3',
            showRecorder: true,
            headers: {
                'X-Custom-Header': 'some data'
            },
        }
    },
    methods:{
        callback (msg) {
            console.debug('Event: ', msg)
        },
        onExportFile(file){
            this.$emit('exportFile', file)
        },
        removeFile(){
            this.$emit('removeFile')
        }
    }
}
</script>