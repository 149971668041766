<template>
    <div v-if="post" class="card">
        <div class="card-body">
            <div v-if="isSubmitted" class="">
                <div class="text-center">
                    <div v-if="postScore && postScore.is_correct">
                        <h2 class="text-success"> <i class="mdi mdi-check-all"></i></h2>
                        <h5><span class="text-success font-weight-bolder">Congratulations!</span> You have just submitted your quiz and you got it right. Keep it up</h5>
                    </div>
                    <div v-else>
                        <h2 class="text-danger"> <i class="mdi mdi-close-outline"></i></h2>
                        <h5><span class="text-danger font-weight-bolder">Wrong!</span> You have just submitted your quiz and you failed it. Question will show again after 7 days.</h5>
                    </div>
                </div>
                <div class="text-center">
                    <b-button @click.prevent="$emit('next')" class="btn-soft-primary" variant="primary" size="sm">
                        Next quiz
                    </b-button>
                </div>
            </div>
            <div v-else>
                <h4>Quiz: #{{index}}</h4>
                <p class="font-weight-bold">
                    {{post.content}}
                </p>
                <div class="mb-3">
                    <p>
                        <span class="mr-2">(A) {{post.option_a}}</span>
                        <span class="mr-2">(B) {{post.option_b}}</span>
                        <span class="mr-2">(C) {{post.option_c}}</span>
                        <span class="mr-2">(D) {{post.option_d}}</span>
                    </p>
                    <b-form-group label="Choose the correct option" class="mb-2" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                            id="radio-group-2"
                            v-model="chosenAnswer"
                            :aria-describedby="ariaDescribedby"
                            name="correct_option"
                        >
                            <b-form-radio value="A">A</b-form-radio>
                            <b-form-radio value="B">B</b-form-radio>
                            <b-form-radio value="C">C</b-form-radio>
                            <b-form-radio value="D">D</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </div>
                 <b-button @click.prevent="submitQuiz()" class="btn btn-soft-success" variant="success" size="sm">
                    <i class="mdi mdi-content-save"></i> Submit
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        index:{
            type: Number,
            required: true,
        },
        post:{
            type: Object,
            default: null
        },
    },
    data() {
		return {
            isSubmitted: false,
            chosenAnswer: null,
            postScore: null,
		}
	},
    methods:{
        compileData(){
            return {
                post_id: this.post.id,
                group_id: this.$route.params.groupId,
                chosen: this.chosenAnswer,
            }
        },
        submitQuiz(){
            if(!this.compileData().post_id || !this.compileData().chosen){
            this.alertError("You have not selected any option"); return}
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.compileData()))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post("/posts/submit-quiz",formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue",false)
                if(response.data.success){
                    this.postScore = response.data.data
                    this.isSubmitted = true
                }
            })
        },
        initializeAll(){
            this.chosenAnswer = null
        }
    }
}
</script>

<style>

</style>