<template>
<div v-if="post">
    <div class="">
        <div class="">
            <div class="media mt-1">
                <div class="media-body">
                    <span class="float-right"><small>{{post.created_at | date_time(1)}}</small></span>
                    <!-- <span class="float-right"><span> <span class="border-right cursor-pointer d-inline-block" v-b-tooltip.hover title="Endorse">{{post.endorse.length}} <i class="mdi mdi-thumb-up d-inline-block mr-1"></i></span> <span class="cursor-pointer" v-b-tooltip.hover title="Dispute"> {{post.dispute.length}} <i class="mdi mdi-thumb-down d-inline-block"></i></span></span></span> -->
                    <b-button @click.prevent="$emit('returnBack')" class="btn-soft-secondary" variant="secondary" size="sm">
                        <i class="mdi mdi-chevron-left"></i> View List
                    </b-button> <br>
                </div>
            </div>
        </div>
        <hr class="my-2" />
        <p>
            <span class="bg-soft-primary badge mr-2">Type: {{ post.type}}</span>
            <span class="bg-soft-success badge">Level: {{ post.level}}</span>
        </p>
        <dl class="row mt-3">
            <dt class="col-sm-4 text-capitalize">{{post.type}}</dt>
            <dd class="col-sm-8"><div v-html="preText(post.content)"></div></dd>
            <dt class="col-sm-4" v-if="post.translation">Translation</dt>
            <dd class="col-sm-8" v-if="post.translation"> <div v-html="preText(post.translation)"></div></dd>
        </dl>
        <hr />
        <div v-if="post.voice_path" class="row">
            <div class="col-sm-6 col-lg-5">
                <h5 class="mb-3">Voice Attachments</h5>
                 <div>
                    <audio
                        hieght="23"
                        controls
                        :src="absoluteUrl(post.voice_path)">
                            Your browser does not support the
                            <code>audio</code> element.
                    </audio>
                </div>
            </div>
            <div class="col-12"><hr /></div>
        </div>
        <div v-if="post.type == 'phrase' || post.type == 'word'">
            <a href="#" style="border-bottom:1px dashed #000; font-size:12px;" @click.prevent="showUsage = !showUsage;" class="mb-0">{{showUsage? 'Hide': 'Show' }} Usage Examples</a>
            <ol v-if="showUsage" class="mt-2 pl-3" >
                <li>
                    <span>
                        <dt>{{post.first_usage}} </dt>
                        <dd> Translation: {{post.first_usage_trans}}</dd>
                    </span>
                </li>
                <li>
                    <span>
                        <dt>{{post.second_usage}} </dt>
                        <dd> Translation: {{post.second_usage_trans}}</dd>
                    </span>
                </li>
                <li>
                    <span>
                        <dt>{{post.third_usage}} </dt>
                        <dd> Translation: {{post.third_usage_trans}}</dd>
                    </span>
                </li>
            </ol>
        </div>
    </div>
</div>
</template>

<script>

export default {
    props:{
        post:{
            type: Object,
            default: null
        },
    },
    data(){
        return {
            showUsage: true,
        }
    },
    computed: {
        activeUser() {
            return this.$store.state.AppActiveUser
        },
    },
};
</script>

<style>

audio {
    width: 100%;
    height: 30px;
}
</style>
