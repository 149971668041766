
<template>
  <div class="ar">
    <div class="ar__overlay" v-if="isUploading"></div>
    <div class="ar-spinner" v-if="isUploading">
      <div class="ar-spinner__dot"></div>
      <div class="ar-spinner__dot"></div>
      <div class="ar-spinner__dot"></div>
    </div>

    <div class="ar-content" :class="{'ar__blur': isUploading}">
    
      <div class="" v-if="recordList.length && selected.url">
        <audio-player :record="selected" ref="audioPlayer"/>
        <div class="d-flex justify-content-center mt-3 text-center">
          <button style="height: 40px; width: 40px;" @click="removeRecord(selected)" class="btn btn-sm btn-dark p-0 mr-1"><i class="ri ri-close-line"></i></button>
          <button style="height: 40px; width: 40px;" v-if="showUploadButton" @click.prevent="download" class="btn btn-sm btn-secondary p-0"><i class="ri ri-download-2-fill"></i></button>
        </div>
      </div>
      <div v-else class="text-center position-relative" >
        <div class="ar-recorder">
          <icon-button
            class="ar-icon ar-icon__lg mr-1"
            :name="iconButtonType"
            :class="{
              'ar-icon--rec': isRecording,
              'ar-icon--pulse': isRecording && volume > 0.02
            }"
            @click.native="toggleRecorder"/>
          <!-- <icon-button
            class="ar-icon ar-icon__sm ar-recorder__stop"
            name="stop"
            @click.native="stopRecorder"/> -->
        </div>
        <div class="ar-recorder__duration">{{recordedTime}}</div>
        <h6 class="mb-0">Upload voice note</h6>
        <div v-if="time" class="text-primary mb-1" style="font-size:13px;">
          <em>{{time}} secs limit</em>
        </div>
        <button @click.prevent="stopRecorder" class="btn btn-sm btn-primary">Add</button>
      </div>
    </div>
  </div>
</template>

<script>
  import AudioPlayer from './player'
  import IconButton  from './icon-button'
  import Recorder    from '../lib/recorder'
  import { convertTimeMMSS }  from '../lib/utils'

  export default {
    props: {
      filename : { type: String },
      format   : { type: String },
      attempts : { type: Number },
      time     : { type: Number },

      bitRate    : { type: Number, default: 128   },
      sampleRate : { type: Number, default: 44100 },

      showDownloadButton : { type: Boolean, default: true },
      showUploadButton   : { type: Boolean, default: true },

      micFailed        : { type: Function },
      beforeRecording  : { type: Function },
      pauseRecording   : { type: Function },
      afterRecording   : { type: Function },
      failedUpload     : { type: Function },
      beforeUpload     : { type: Function },
      successfulUpload : { type: Function },
      selectRecord     : { type: Function }
    },
    data () {
      return {
        isUploading   : false,
        recorder      : this._initRecorder(),
        recordList    : [],
        selected      : {},
        uploadStatus  : null,
      }
    },
    components: {
      AudioPlayer,
      IconButton,
    },
    beforeDestroy () {
      this.stopRecorder()
    },
    methods: {
      upload () {
        if (!this.selected.url) {
          return
        }
        // this._onStartUpload();
        // const data = new FormData()
        // data.append('audio', this.selected.blob, `${this.filename}.mp3`)
        this.$emit('export', {...this.selected});
      },
      download () {
        if (!this.selected.url) {
          return
        }
        const type = this.selected.blob.type.split('/')[1]
        const link = document.createElement('a')
        link.href = this.selected.url
        link.download = `${this.filename}.${type}`
        link.click()
      },
      toggleRecorder () {
        if (this.attempts && this.recorder.records.length >= this.attempts) {
          return
        }
        if (!this.isRecording || (this.isRecording && this.isPause)) {
          this.recorder.start()
        } else {
          this.recorder.pause()
        }
      },
      stopRecorder () {
        if (!this.isRecording) {
          return
        }
        this.recorder.stop()
        this.recordList = this.recorder.recordList()
        this.choiceRecord(this.recordList.length ? this.recordList[0] : false)
      },
      removeRecord (idx) {
        this.$emit('remove');
        this.recordList.splice(idx, 1)
        this.$set(this.selected, 'url', null)
        this.$refs.audioPlayer._resetProgress()
        
      },
      choiceRecord (record) {
        if (!record || this.selected === record ) {
          return
        }
        this.selected = record
        this.upload();
        this.selectRecord && this.selectRecord(record)
      },
      _initRecorder () {
        return new Recorder({
          beforeRecording : this.beforeRecording,
          afterRecording  : this.afterRecording,
          pauseRecording  : this.pauseRecording,
          micFailed       : this.micFailed,
          bitRate         : this.bitRate,
          sampleRate      : this.sampleRate,
          format          : this.format
        })
      },
      _onStartUpload(){
        this.isUploading = true
        this.beforeUpload && this.beforeUpload('before upload')
      },
      _onEndUpload(msg){
        this.isUploading = false
        if (msg.status === 'success') {
          this.successfulUpload && this.successfulUpload(msg.response)
        } else {
          this.failedUpload && this.failedUpload(msg.response)
        }
      },
    },
    computed: {
      attemptsLeft () {
        return this.attempts - this.recordList.length
      },
      iconButtonType () {
        return this.isRecording && this.isPause ? 'mic' : this.isRecording ? 'pause' : 'mic'
      },
      isPause () {
        return this.recorder.isPause
      },
      isRecording () {
        return this.recorder.isRecording
      },
      recordedTime () {
        if (this.time && this.recorder.duration >= this.time) {
          this.stopRecorder()
        }
        return convertTimeMMSS(this.recorder.duration)
      },
      volume () {
        return parseFloat(this.recorder.volume)
      }
    }
  }
</script>

<style lang="scss">
  .ar {
    // font-family: 'Roboto', sans-serif;
    border-radius: 16px;
    // background-color: #FAFAFA;
    // box-shadow: 0 4px 18px 0 rgba(0,0,0,0.17);
    position: relative;
    box-sizing: content-box;

    &-content {
      padding: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-records {
      // height: 138px;
      padding-top: 1px;
      overflow-y: auto;
      margin-bottom: 20px;

      &__record {
        width: 268px;
        height: 46px;
        padding: 0 10px;
        margin: 0 auto;
        line-height: 45px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #E8E8E8;
        position: relative;

        &--selected {
          border: 1px solid #E8E8E8;
          border-radius: 24px;
          background-color: #FFFFFF;
          margin-top: 3px;
          padding: 0 34px;
        }
      }
    }

    &-recorder {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &__duration {
        color: #AEAEAE;
        font-size: 32px;
        font-weight: 500;
        margin-top: 3px;
        margin-bottom: 0px;
      }

      // &__stop {
      //   position: absolute;
      //   top: 8px;
      //   right: -31px;
      // }

      &__time-limit {
        position: absolute;
        color: #AEAEAE;
        font-size: 12px;
        top: 128px;
      }

      &__records-limit {
        position: absolute;
        color: #AEAEAE;
        font-size: 13px;
        top: 70px;
      }
    }

    &-spinner {
      display: flex;
      height: 30px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 144px;
      z-index: 10;

      &__dot {
        display: block;
        margin: 0 8px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background: #05CBCD;
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;

        &:nth-child(2) { animation-delay: .2s; }

        &:nth-child(3) { animation-delay: .4s; }

        @keyframes blink {
          0%    { opacity: .2; }
          20%   { opacity: 1;  }
          100%  { opacity: .2; }
        }
      }
    }

    &__text {
      color: rgba(84,84,84,0.5);
      font-size: 16px;
    }

    &__blur {
      filter: blur(2px);
      opacity: 0.7;
    }

    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
    }

    &__upload-status {
      text-align: center;
      font-size: 10px;
      padding: 2px;
      letter-spacing: 1px;
      position: absolute;
      bottom: 0;

      &--success {
        color: green;
      }

      &--fail {
        color: red;
      }
    }

    &__rm {
      cursor: pointer;
      position: absolute;
      width: 6px;
      height: 6px;
      padding: 6px;
      line-height: 6px;
      margin: auto;
      left: 10px;
      bottom: 0;
      top: 0;
      color: rgb(244, 120, 90);
    }

    &__downloader,
    &__uploader {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &__downloader {
      right: 90px;
    }

    &__uploader {
      right: 85px;
    }
  }

  @import '../scss/icons';
</style>
