<template>
	<div class="group-container pt-3">
        <div v-if="!isLoading">
            <group-portlet headertitle="Group Info" :open="true">
                <div class="card-body pt-0">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <h5 class="mb-1">Welcome to {{group.name}} Language Group</h5>
                            <p  class="mb-2 pb-2 border-bottom">{{group.description}}</p>
                            <div class="font-12 mb-1">
                                <span v-if="group.countries.length" class="mr-2">
                                    <dt class="d-inline">{{(group.countries.length>1)? "Countries": "Country"}}: </dt>
                                    <dd class="d-inline">{{group.countries.map((i) => i.name).join(', ')}} </dd>
                                </span>
                                <span>
                                    <dt class="d-inline">Region: </dt>
                                    <dd class="d-inline">{{group.region}}</dd>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </group-portlet>
            <div class="row mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="media mb-3">
                                <div class="media-body">
                                    <h5 class="mt-2 mb-2 font-15">
                                        Your level in {{group.name}}
                                    </h5>
                                    <p class="text-muted mb-0">
                                       Your level progression is determined by the number of quizzes you successfully pass. Consistent quiz completion helps in advancing to higher levels.
                                    </p>
                                </div>
                            </div>
                            <span class="bg-soft-info mb-2 font-12 text-info badge">You have spent {{daySpent}} {{daySpent > 1 ? 'days' : 'day'}}</span>
                            <b-button block class="btn-soft-secondary text-capitalize" variant="secondary" size="sm">
                                <i class="mdi mdi-account-star"></i> Your Current Level : {{group.belong.level}}
                            </b-button>
                            <div class="mt-3" v-if="group.belong.certificate_issued">
                                <p class="mb-1"> <strong class="text-success">Congratulations!</strong> Having completed all the essays. Lanuageknow awarded you a certificate of basic language proficiency.</p>
                                <a :href="absoluteUrl(group.belong.certificate)" download class="btn-soft-success btn-sm">
                                    <i class="mdi mdi-certificate-outline"></i> Download Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <button :class="viewType == 'lesson' ? 'btn-primary':'btn-secondary'"
                        @click.prevent="viewType = 'lesson'"
                        class="btn mr-2 mb-2" type="button"> Take Lesson </button>
                    <button :class="viewType == 'quiz' ? 'btn-primary':'btn-secondary'"
                        @click.prevent="viewType = 'quiz'"
                        class="btn btn-primary mr-2 mb-2" type="button"> Take quiz  </button>
                    <button :class="viewType == 'essay' ? 'btn-primary':'btn-secondary'"
                        @click.prevent="viewType = 'essay'"
                        class="btn mb-2 btn-primary" type="button"> View Essay  </button>
                </div>
            </div>
            <div class="row mt-1">
                <div v-if="viewType == 'lesson'" class="col-12">
                    <post-table />
                </div>
                <div v-if="viewType == 'quiz'" class="col-12">
                    <quiz-table />
                </div>
                <div v-if="viewType == 'essay'" class="col-12">
                    <EssayContent  />
                </div>
            </div>
        </div>
        <is-loading v-else/>
	</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue';
import GroupPortlet from "@/components/group/GroupPortlet.vue";
import EssayContent from "@/components/group/EssayContent.vue"
import PostTable from '@/components/group/PostTable.vue';
import QuizTable from '@/components/group/QuizTable.vue';

export default {
	components: {
        IsLoading,
        GroupPortlet,
        EssayContent,
        PostTable,
        QuizTable
	},
	data() {
		return {
            isLoading: true,

            viewType: 'lesson',
		}
	},
    watch: {
        "$route": "fetchGroup"
    },
	computed: {
        activeUser(){
            return this.$store.state.AppActiveUser;
        },
        group(){
            return this.$store.state.groupList.group
        },
        daySpent(){
          return this.group.belong.last_upgraded_at
              ? Math.round((new Date().getTime() - new Date(this.group.belong.last_upgraded_at).getTime())/(1000 * 3600 * 24))
              : 0
        }
	},
	methods: {
        changeViewType(val){
            this.viewType = val;
        },
        fetchGroup(){
            this.isLoading = true
            this.$store.dispatch("groupList/fetchGroup",this.$route.params.groupId)
            .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    if (response.data.data.interrupt) {
                        this.$router.push({path: `/subscriptions/${response.data.data.sub_id}`})
                    }
                }else{
                    this.$router.push({path: '/error-404'})
                }
            })
        },
	},
    created(){
        this.fetchGroup()
    },
    mounted() {
        this.$store.dispatch('togglePageHeaderFooter',{ header:false, footer:false})
        this.$root.$on('bv::dropdown::show', bvEvent => {
            if(bvEvent.componentId === 'dropdown-step2') {
            this.isDropdown2Visible = true;
            }
        })
        this.$root.$on('bv::dropdown::hide', bvEvent => {
            if(bvEvent.componentId === 'dropdown-step2') {
            this.isDropdown2Visible = false;
            }
            if(this.isDropdown2Visible) {
             bvEvent.preventDefault()
            }
        })
	},
    beforeDestroy(){
        this.$store.dispatch('togglePageHeaderFooter',{header:true, footer:true})
    }

}
</script>

<style lang="scss">
.custom-dropdown-sm{
 .dropdown-menu.show{
    position:absolute !important
 }
}
.no-drop-padding {
    .btn{
        padding: 0px;
    }
}
</style>
