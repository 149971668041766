<template>
    <div class="card bg-transparent border-0">
        <div v-if="isLoading" class="pb-5 card-disabled">
            <div class="card-portlets-loader">
                <div class="spinner-border text-primary m-2" role="status"></div>
            </div>
        </div>
        <div v-else class="">
            <div class="pb-2" >
                <span class="mr-2">Total number of quizzes: {{postData.total}}</span>
            </div>
            <div v-if="posts.length">
                <div v-for="(post, pIndex) in posts" :key="pIndex">
                    <quiz-content v-if="pIndex == currentQuiz" :post="post"
                        @next="next()" :index="pIndex+1" />
                </div>
            </div>
            <p v-else class="text-center"> No quiz to show</p>
        </div>
    </div>
</template>


<script>
import QuizContent from './QuizContent.vue'

export default {
  components: { QuizContent },
    props:{
        post:{
            type: Object,
            default: null
        },
    },
	data() {
		return {
            isLoading: true,
            currentQuiz: 0,
		}
	},
	computed: {
        postData(){
            return this.$store.state.groupList.postData
        },
        posts() {
            return this.postData.data
        },
	},
	methods: {
        next(){
            this.viewPost = null
            if((this.posts.length - 1) > this.currentQuiz ){
              this.currentQuiz = this.currentQuiz + 1
            }else if((this.posts.length - 1) == this.currentQuiz){
                toastr.info("That was the last!", 'Notice');
            }
        },
        fetchPosts(page, per_page = null){
            let payload = {group_id: this.$route.params.groupId, type: 'quiz',
             page: page || 1, per_page: per_page || 50 }
             
            this.isLoading = true
            this.$store.dispatch("groupList/fetchPosts", payload)
            .then(response => this.isLoading = false)
        },
	},
    mounted(){
        this.fetchPosts()
    }

}
</script>
