<template>
    <div class="">
        <div v-if="group.belong.level == 'advanced'">
            <div class="row">
                <div class="col-12">
                    <div role="tablist">
                        <b-card v-for="(essay, index) in essays" :key="index" no-body class="mb-2 custom-accordion">
                            <b-card-header header-tag="header" class="bg-transparent" role="tab">
                                <h5 class="m-0 position-relative">
                                    <a v-b-toggle="'accordion-'+essay.id" class="text-reset" href="javascript: void(0);">
                                        {{index+1}}.) {{essay.topic}}
                                        <i class="mdi mdi-chevron-down accordion-arrow"></i>
                                    </a>
                                </h5>
                            </b-card-header>
                            <b-collapse :id="`accordion-${essay.id}`" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <dl class="row">
                                        <dt class="col-12 text-capitalize">Body:</dt>
                                        <dd class="col-12"><div class="pl-3"><div v-html="preText(essay.content)"></div></div></dd>
                                        <dt class="col-12">Translation:</dt>
                                        <dd class="col-12"><div class="pl-3"> <div v-html="preText(essay.translation)"></div></div></dd>
                                    </dl>
                                    <span>Submitted on: {{essay.created_at | date_time(1)}}</span>
                                    <hr />
                                    <div v-if="essay.voice_path" class="row">
                                        <div class="col-sm-6 col-lg-5">
                                            <h5 class="mb-2">Voice Attachments</h5>
                                            <div>
                                                <audio
                                                    hieght="23"
                                                    controls
                                                    :src="absoluteUrl(essay.voice_path)">
                                                        Your browser does not support the
                                                        <code>audio</code> element.
                                                </audio>
                                            </div>
                                        </div>
                                        <div class="col-12"><hr /></div>
                                    </div>
                                    <div>
                                        <div v-if="essay.comments.length" >
                                            <h5 class="mb-2">Reviews</h5>
                                            <ul class="list-group list-group-flush">
                                                <li v-for="(comment, incom) in essay.comments" :key="incom" class="list-group-item px-0 mb-0 bg-transparent">
                                                    <div class="media align-items-center">
                                                        <div class="mr-3">
                                                            <strong>Teacher {{incom+1}}</strong>
                                                            <h5 class="my-1">{{comment.score}}% </h5>
                                                        </div>
                                                        <div class="media-body">
                                                            <span v-if="comment.score < 50" class="bg-soft-danger text-danger badge mr-2">Poor</span>
                                                            <span v-if="comment.score > 49" class="bg-soft-success text-success badge mr-2">Passed</span>
                                                            <dd> {{comment.remark}}</dd>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <p v-else>No review yet</p>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </div>
                <div class="col-12">
                    <div class="text-right mt-3" >
                        <b-button @click.prevent="uploadEssayModal = true" class="btn-primary" variant="primary">
                            <i class="mdi mdi-plus-circle mr-1"></i> Write Essay
                        </b-button>
                    </div>
                </div>
            </div>

            <b-modal centered id="modal-2" v-model="uploadEssayModal" 
            :title="editMode ? 'Upload essay' : 'Edit essay'" header-close-variant="primary" 
            title-class="font-18 text-primary" hide-footer @hide="initializeAll()">
                <upload-essay v-if="uploadEssayModal" @closeMe="initializeAll()"
                    :editMode="editMode" :editItem="editItem"/>
            </b-modal>
        </div>
        <div v-else class="row">
            <div class="col-12">
                <div class="" >
                    <b-alert show variant="info">
                        <h4 class="alert-heading">Note!</h4>
                        <hr class="my-1">
                        <p class="mb-0">
                        Essay writing is for Advanced Learners.
                        </p>
                    </b-alert>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import UploadEssay from "@/components/forms/UploadEssay.vue"


export default {
    components:{
        UploadEssay,
    },
	data() {
		return {
            uploadEssayModal: false,

            editMode: false,
            editItem: null,
		}
	},
	computed: {
        essays(){
            return this.$store.state.groupList.group.essays
        },
        group(){
            return this.$store.state.groupList.group
        },
	},
	methods: {
        initializeAll(){
            this.uploadEssayModal = false
        }
	}

}
</script>

<style>

audio {
    width: 100%;
    height: 30px;
}
</style>s
