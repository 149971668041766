<template>
  <div>
    <div class="card">
        <div v-if="isLoading" class="card-disabled">
            <div class="card-portlets-loader">
                <div class="spinner-border text-primary m-2" role="status"></div>
            </div>
        </div>
        <div class="card-body">
            <div v-if="!isPostView">
                <div class="d-sm-flex align-items-center">
                    <b-dropdown variant="light" class="mb-2 mr-2 mb-sm-0">
                        <template slot="button-content">
                            Filter by type
                        </template>
                        <b-dropdown-item-button @click="fetchPostByType('all')"> <i class="mdi mdi-text-box-multiple align-bottom mr-2"></i>All Posts </b-dropdown-item-button>
                        <b-dropdown-item-button @click="fetchPostByType('alphabet')"> <i class="mdi mdi-tag-text-outline align-bottom mr-2"></i>Alphabets</b-dropdown-item-button>
                        <b-dropdown-item-button @click="fetchPostByType('word')"> <i class="mdi mdi-file-document-outline align-bottom mr-2"></i>Words</b-dropdown-item-button>
                        <b-dropdown-item-button @click="fetchPostByType('phrase')"> <i class="ri-file-list-2-line align-bottom mr-2"></i>Phrases/Sentences </b-dropdown-item-button>
                    </b-dropdown>
                    <form class="flex-grow-1 d-inline-block m-0">
                        <div class="input-group">
                            <input v-model="postSearchQuery" @input="updateSearchQuery" type="search" class="form-control form-control-white"
                                placeholder="Search for content..." />
                            <div class="input-group-prepend">
                                <b-dropdown variant="secondary" right>
                                    <template slot="button-content">
                                        <i class="mdi mdi-filter-variant"></i>
                                    </template>
                                        <b-dropdown-item-button @click="fetchPostBySort('asc')"> Ascending </b-dropdown-item-button>
                                        <b-dropdown-item-button @click="fetchPostBySort('desc')"> Descending </b-dropdown-item-button>
                                </b-dropdown>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="mt-2">
                    <strong>Filters:</strong> <span>Type: <span class="text-capitalize">{{postType}}</span></span> | <span>Sort: <span class="text-uppercase">{{postSort}}</span></span>
                </div>
                <div class="mb-4">
                    <div class="table-responsive mb-0">
                        <table class="table table-borderless table-hover table-centered m-0">
                            <thead class="">
                                <tr>
                                    <th class="pl-0">Types</th>
                                    <th>Content</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr @click="openPostView(post)" v-for="(post, index) in posts" :key="index" class="cursor-pointer font-14" >
                                    <td class="pl-0">
                                        <h5 class="m-0 font-weight-normal text-capitalize" >{{ post.type }}</h5>
                                    </td>
                                    <td>{{ post.content | truncate(30)}}</td>
                                    <td class="font-12">
                                        <div style="min-width:5rem;">{{ post.created_at | date_ago }}</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row justify-content-md-between align-items-md-center">
                    <div class="col-12">
                        <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                                :total-rows="postData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
                    </div>
                </div>
            </div>
            <div v-if="isPostView">
                <post-content @returnBack="closePostView()"  :post="viewPost"/>
            </div>
        </div>
    </div>
    
  </div>
</template>

<script>
import PostContent from './PostContent.vue'
import _ from 'lodash';

export default {
    components: { PostContent },
    props:{
        fetchItems:{
            type: Function
        }
    },
    data() {
		return {
            isLoading: true,
            sordPostBy: '',

            viewPost: null,
            isPostView: false,
            postSort: 'asc',
            postType: 'all',

            postSearchQuery: "",
		}
	},
    computed: {
        postData(){
            return this.$store.state.groupList.postData
        },
        posts() {
            return this.postData.data
        },
        currentPage: {
            get() {
                return this.postData.current_page
            },
            set(val) {
                if(!this.postData.current_page || this.postData.current_page == val) return
                this.fetchItems(val)
            }
        },
        itemsPerPage: {
            get() {
                return +this.postData.per_page || 50
            },
            set(val) {
                this.fetchItems(1, val)
            }
        },
    },
    methods:{
        openPostView(post){
            this.isPostView = true
            this.viewPost = post;
        },
        fetchPostByType(type){
            this.postType = type;
            this.isPostView = false;
            this.fetchPosts()
        },
        fetchPostBySort(sort){
            this.postSort = sort;
            this.isPostView = false;
            this.fetchPosts()
        },
        closePostView(){
            this.isPostView = false
        },
        updateSearchQuery: _.debounce(function(string) {
            this.fetchPosts()
        }, 2000),
        fetchPosts(page, per_page = null){
            let payload = {group_id: this.$route.params.groupId, page: page || 1, sort_by: this.postSort, per_page: per_page || 50 }
            if(this.postType != 'all'){ payload.type = this.postType }
            if(this.postSearchQuery){ payload.search = this.postSearchQuery }
            this.isLoading = true
            this.$store.dispatch("groupList/fetchPosts", payload)
            .then(response => this.isLoading = false)
        },
    },
    mounted(){
        this.fetchPosts()
    }
}
</script>

<style>

</style>