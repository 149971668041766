<template>
<div v-if="isVisible" class="card mb-1">
    <div class="card-body">
        <div class="card-widgets">
            <a href="javascript: void(0);" @click="refreshContent">
                <i class="mdi mdi-refresh"></i>
            </a>
            <a :class="[
            showCollapse ? 'collapsed' : null,
            showCollapse ? 'mdi mdi-minus' : 'mdi mdi-plus'
          ]" :aria-expanded="showCollapse ? 'true' : 'false'" aria-controls="collapse-1" @click="collapse"></a>
            <router-link to="/my-languages">
                <i class="mdi mdi-close"></i>
            </router-link>
        </div>
        <h4 class="header-title mb-0" :class="portletclass">{{ headertitle }}</h4>
    </div>
    <!-- End card header -->

    <b-collapse id="collapse-1" v-model="showCollapse">
        <slot></slot>
    </b-collapse>

    <div v-if="isLoading" class="card-disabled">
        <div class="card-portlets-loader">
            <div class="spinner-border text-primary m-2" role="status"></div>
        </div>
    </div>
</div>
</template>


<script>
export default {
    props: {
        headertitle: {
            type: String,
            default: ""
        },
        portletclass: {
            type: String,
            default: ""
        },
        open:{
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isVisible: true,
            showCollapse: false,
            isLoading: false
        };
    },
    methods: {
        refreshContent() {
            this.isLoading = true;
            this.$store.dispatch("groupList/fetchGroup", this.$route.params.groupId)
            .then((response) => {
                this.isLoading = false
            })
        },
        remove() {
            this.isVisible = false;
        },
        collapse() {
            this.showCollapse = !this.showCollapse;
        }
    },
    mounted(){
      if(this.open) {this.showCollapse = true}
    }
};
</script>

