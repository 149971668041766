

<template>
  <div id="upload-sentence">
    <div class="row justify-content-center">
        <div class="col-12 mb-3">
            <b-form-select v-validate="'required'" v-model="data.topic_id" name="topic" class="rounded-0 border-left-0 border-right-0 border-top-0 mb-0">
                <template #first>
                    <b-form-select-option :value="null" disabled>-- Select Topic --</b-form-select-option>
                </template>
                <b-form-select-option v-for="topic in essayTopics" :value="topic.id" :key="topic.id">{{topic.topic}}</b-form-select-option>
            </b-form-select>
            <span class="text-danger font-sm"  v-show="errors.has('topic')">{{ errors.first('topic') }}</span>
        </div>
        <div class="col-12 d-flex align-items-center">
             <div class="form-group w-100">
                  <textarea  v-model="data.content" class="form-control rounded-0 border-left-0 border-right-0 border-top-0" rows="4"
                   placeholder="Write Essay..." v-validate="'max:500'" name="essay"></textarea>
                <span class="text-danger font-sm"  v-show="errors.has('essay')">{{ errors.first('essay') }}</span>
            </div>
        </div>
        <div class="col-12">
           <div class="form-group w-100">
                  <textarea  v-model="data.translation" class="form-control rounded-0 border-left-0 border-right-0 border-top-0" rows="4"
                   placeholder="Translate in English..." v-validate="'max:500'" name="translation"></textarea>
                <span class="text-danger font-sm"  v-show="errors.has('translation')">{{ errors.first('translation') }}</span>
            </div>
        </div>
        <div class="col-12 col-lg-8 mt-1">
          <audio-recorder :time="60" @exportFile="setVoice($event)" @removeFile="setVoice(null)" />
        </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="mt-3">
          <b-button variant="primary" @click.prevent="editMode? update() : create()" type="button">{{editMode ? 'Update':'Submit'}}</b-button>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import AudioRecorder from '@/components/AudioRecorder.vue';

export default {
  components:{
    AudioRecorder
  },
  props:{
    editItem:{
      type: Object,
      default: null
    },
    editMode:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {
        topic_id:null,
        content: "",
        translation: "",
        group_id: this.$route.params.groupId
      },
      file: null
    }
  },
  computed: {
    essayTopics(){
      return this.$store.state.groupList.group.essay_topics
    },
  },
  methods: {
    create() {
      this.$validator.validateAll().then(result => {
        if (!result) {this.alertError("Form not valid"); return }
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        if(this.file){ formData.append('voice_file', this.file.blob, 'voice-record.mp3') }
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/essays/create', formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
                this.$store.commit("groupList/ADD_ESSAY", response.data.data)
                this.resetForm();
            }
        })
      })
    },
    update(){
      this.$validator.validateAll().then(result => {
        if (!result) {this.alertError("Form not valid"); return }
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        if(this.file){ formData.append('voice_file', this.file.blob, 'voice-record.mp3') }
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/essays/${this.editItem.id}/update`,formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.commit("groupList/UPDATE_ESSAY", response.data.data)
            this.resetForm()
          }
        })
      })
    },
    setVoice(file){
      this.file = file
    },
    resetForm() {
      this.file = null
      this.$emit('closeMe');
    },
  },
  mounted(){
    if(this.editMode){
      this.data = this.$options.filters
                  .patchUpdatables(this.data, this.editItem)
    }
  }
}
</script>
